var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from 'react';
import { Row, Col, Checkbox, Button, AccordionSet, Accordion } from '@folio/stripes/components';
import { useHistory } from 'react-router-dom';
import { fetchAddressCode } from '../../api/publisherService';
import { useOkapiKy } from '@folio/stripes/core';
var AresUpdatesDetail = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var ares = _a.ares;
    var ky = useOkapiKy();
    var history = useHistory();
    var _t = useState({
        ares: true,
        general: true,
        address: true,
        contacts: true,
        comments: true,
        timestamps: true,
    }), accordionStatus = _t[0], setAccordionStatus = _t[1];
    var _u = useState(true), expandAll = _u[0], setExpandAll = _u[1];
    var _v = useState(null), addressData = _v[0], setAddressData = _v[1];
    var formatDate = function (dateString) {
        if (!dateString)
            return 'N/A'; // Return 'N/A' if the date is undefined or null
        var date = new Date(dateString);
        var day = date.getDate().toString().padStart(2, '0');
        var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        var year = date.getFullYear();
        var hours = date.getHours().toString().padStart(2, '0');
        var minutes = date.getMinutes().toString().padStart(2, '0');
        return "".concat(day, ".").concat(month, ".").concat(year, " ").concat(hours, ":").concat(minutes);
    };
    var contactTypeTranslations = {
        EMAIL: 'E-mail',
        WEB: 'Web',
        PHONE: 'Telefon',
        DATA_BOX: 'Datová schránka',
    };
    // Použití překladu při vykreslování
    var renderField = function (label, value) {
        var displayValue = value;
        if (label === 'Typ' && typeof value === 'string') {
            displayValue = contactTypeTranslations[value] || value;
        }
        return (React.createElement(Row, { style: { marginBottom: '25px' } },
            React.createElement(Col, { xs: 12 },
                React.createElement("div", { style: { marginBottom: '5px' } },
                    React.createElement("strong", { style: { fontSize: '1.1em', paddingRight: '5px' } }, label)),
                React.createElement("div", null, displayValue || 'N/A'))));
    };
    useEffect(function () {
        var loadAddress = function () { return __awaiter(void 0, void 0, void 0, function () {
            var data, error_1;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, fetchAddressCode(ky, (_a = ares.data.addressCode) !== null && _a !== void 0 ? _a : '')];
                    case 1:
                        data = _b.sent();
                        setAddressData(data);
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _b.sent();
                        console.error("Failed to fetch address data:", error_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        loadAddress();
    }, [ares.data.addressCode]);
    var hasMailingAddress = ((_b = ares.publisher.mailingAddress) === null || _b === void 0 ? void 0 : _b.street) || ((_c = ares.publisher.mailingAddress) === null || _c === void 0 ? void 0 : _c.postalCode) || ((_d = ares.publisher.mailingAddress) === null || _d === void 0 ? void 0 : _d.municipality);
    var hasContacts = ares.publisher.contacts && ares.publisher.contacts.length > 0;
    var toggleAccordion = function (section) {
        setAccordionStatus(function (prevState) {
            var _a;
            return (__assign(__assign({}, prevState), (_a = {}, _a[section] = !prevState[section], _a)));
        });
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            setAccordionStatus({
                ares: false,
                general: false,
                address: false,
                contacts: false,
                comments: false,
                timestamps: false,
            });
        }
        else {
            setAccordionStatus({
                ares: true,
                general: true,
                address: true,
                contacts: true,
                comments: true,
                timestamps: true,
            });
        }
        setExpandAll(!expandAll);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("h1", null,
            ares.publisher.name,
            ", ",
            ares.publisher.nameSuffix),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12, style: { display: 'flex', justifyContent: 'flex-end' } },
                React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? 'Sbalit všechny' : 'Rozbalit všechny'))),
        React.createElement(AccordionSet, null,
            React.createElement(Accordion, { id: "ares", label: "Data z resjt\u0159\u00EDku", open: accordionStatus.ares, onToggle: function () { return toggleAccordion('ares'); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('IČO', ares.data.companyNumber)),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Jméno', ares.data.name))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('DIČ', ares.data.taxNumber)),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Právní forma', ares.data.legalForm))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 4 }, renderField('Ulice a číslo', addressData === null || addressData === void 0 ? void 0 : addressData.street)),
                    React.createElement(Col, { xs: 12, md: 4 }, renderField('PSČ', addressData === null || addressData === void 0 ? void 0 : addressData.postalCode)),
                    React.createElement(Col, { xs: 12, md: 4 }, renderField('Obec', addressData === null || addressData === void 0 ? void 0 : addressData.municipality))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Sídelní kraj', (_f = (_e = ares.publisher.mailingAddress) === null || _e === void 0 ? void 0 : _e.region) === null || _f === void 0 ? void 0 : _f.name))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Čas editace', formatDate(ares.data.updateTime))))),
            React.createElement(Accordion, { id: "general", label: "Obecn\u00E9 informace", open: accordionStatus.general, onToggle: function () { return toggleAccordion('general'); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('IČO', ares.publisher.companyNumber)),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('DIČ', ares.publisher.taxNumber))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Název', ares.publisher.name)),
                    React.createElement(Col, { xs: 12, md: 6 }, ares.publisher.legalForm === 'NATURAL' ? renderField('Datum narození', ares.publisher.birthDate) : '')),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Doplněk k názvu', ares.publisher.nameSuffix)),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Kvalifikátor', ares.publisher.qualifier))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Právní forma', ares.publisher.legalForm === 'LEGAL' ? 'Právnická osoba' : 'Fyzická osoba')))),
            React.createElement(Accordion, { id: "address", label: "Adresa", open: accordionStatus.address, onToggle: function () { return toggleAccordion('address'); } },
                React.createElement("h3", null, "Adresa s\u00EDdla"),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 4 }, renderField('Ulice a číslo', (_g = ares.publisher.mainAddress) === null || _g === void 0 ? void 0 : _g.street)),
                    React.createElement(Col, { xs: 12, md: 4 }, renderField('PSČ', (_h = ares.publisher.mainAddress) === null || _h === void 0 ? void 0 : _h.postalCode)),
                    React.createElement(Col, { xs: 12, md: 4 }, renderField('Obec', (_j = ares.publisher.mainAddress) === null || _j === void 0 ? void 0 : _j.municipality))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Sídelní kraj', (_l = (_k = ares.publisher.mainAddress) === null || _k === void 0 ? void 0 : _k.region) === null || _l === void 0 ? void 0 : _l.name))),
                hasMailingAddress && (React.createElement(React.Fragment, null,
                    React.createElement("h3", null, "Doru\u010Dovac\u00ED adresa"),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 4 }, renderField('Ulice a číslo', (_m = ares.publisher.mailingAddress) === null || _m === void 0 ? void 0 : _m.street)),
                        React.createElement(Col, { xs: 12, md: 4 }, renderField('PSČ', (_o = ares.publisher.mailingAddress) === null || _o === void 0 ? void 0 : _o.postalCode)),
                        React.createElement(Col, { xs: 12, md: 4 }, renderField('Obec', (_p = ares.publisher.mailingAddress) === null || _p === void 0 ? void 0 : _p.municipality))),
                    React.createElement(Row, null,
                        React.createElement(Col, { xs: 12, md: 6 }, renderField('Sídelní kraj', (_r = (_q = ares.publisher.mailingAddress) === null || _q === void 0 ? void 0 : _q.region) === null || _r === void 0 ? void 0 : _r.name)))))),
            React.createElement(Accordion, { id: "contacts", label: "Kontakty", open: accordionStatus.contacts, onToggle: function () { return toggleAccordion('contacts'); } }, hasContacts ? ((_s = ares.publisher.contacts) === null || _s === void 0 ? void 0 : _s.map(function (contact, index) { return (React.createElement(Row, { key: index },
                React.createElement(Col, { xs: 12, md: 4 }, renderField('Typ', contact.type)),
                React.createElement(Col, { xs: 12, md: 4 }, renderField('Hodnota', contact.value)))); })) : (React.createElement(Row, null,
                React.createElement(Col, { xs: 12 },
                    React.createElement("div", null, "\u017D\u00E1dn\u00E9 kontakty k zobrazen\u00ED"))))),
            React.createElement(Accordion, { id: "comments", label: "Intern\u00ED pozn\u00E1mka", open: accordionStatus.comments, onToggle: function () { return toggleAccordion('comments'); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12 }, renderField('Interní poznámka', ares.publisher.internalComment)))),
            React.createElement(Accordion, { id: "timestamps", label: "\u010Casy", open: accordionStatus.timestamps, onToggle: function () { return toggleAccordion('timestamps'); } },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Čas registrace', formatDate(ares.publisher.createTime))),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Čas aktivace', formatDate(ares.publisher.activationTime)))),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Čas potvrzení údajů', formatDate(ares.publisher.dataConfirmationTime))),
                    React.createElement(Col, { xs: 12, md: 6 }, renderField('Čas uzavření', formatDate(ares.publisher.closeTime)))))),
        React.createElement(Row, null,
            React.createElement(Col, { xs: 12 },
                React.createElement(Checkbox, { label: "Souhlas se zve\u0159ejn\u011Bn\u00EDm", checked: false, disabled: true })))));
};
export default AresUpdatesDetail;
