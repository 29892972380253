var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect, useCallback } from 'react';
import { Pane, Button, Row, Col, TextField, Select, AccordionSet, Accordion, TextArea, Datepicker, Checkbox, Selection, ErrorModal, PaneFooter, } from '@folio/stripes/components';
import { useQuery } from 'react-query'; // Using react-query for fetching data
import { fetchAddress, fetchAddressCode, fetchAresDataByCompanyNumber, fetchPublisherData, fetchRegions, updatePublisher } from '../../api/publisherService';
import { useHistory, useParams } from 'react-router';
import { useOkapiKy } from '@folio/stripes/core';
import { debounce } from 'lodash';
var PublisherEdit = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    var _v = _a.status, status = _v === void 0 ? "EDIT" : _v;
    var id = useParams().id;
    var history = useHistory();
    var ky = useOkapiKy();
    var _w = useState({
        id: '', // Default value for 'id'
        companyNumber: '',
        name: '',
        nameSuffix: '',
        qualifier: '',
        legalForm: 'LEGAL',
        taxNumber: '',
        birthDate: '',
        mainAddress: {
            addressCode: '',
            street: '',
            postalCode: '',
            municipality: '',
            region: { id: '', name: '' }, // New field
        },
        mailingAddress: {
            addressCode: '',
            street: '',
            postalCode: '',
            municipality: '',
            region: { id: '', name: '' }, // New field
        },
        contacts: [],
        alternativeNames: [],
        internalComment: '',
        origin: 'PUBLISHER', // Default value for 'origin'
        status: 'ACTIVE', // Default value for 'status'
        createTime: new Date().toISOString(), // Default value for 'createTime'
        dataConfirmationTime: new Date().toISOString(), // Default value for 'dataConfirmationTime'
    }), formValues = _w[0], setFormValues = _w[1];
    var _x = useState(false), enableMainAddress = _x[0], setEnableMainAddress = _x[1];
    var _y = useState(false), enableMailingAddress = _y[0], setEnableMailingAddress = _y[1];
    var _z = useState({}), errors = _z[0], setErrors = _z[1];
    var _0 = useState(false), expandAll = _0[0], setExpandAll = _0[1];
    var _1 = useState({
        general: true,
        address: true,
        contacts: true,
        alternativeNames: true,
        comments: true,
    }), accordionStatus = _1[0], setAccordionStatus = _1[1];
    var _2 = useState(false), errorModalOpen = _2[0], setErrorModalOpen = _2[1];
    var _3 = useState(''), errorMessage = _3[0], setErrorMessage = _3[1];
    var _4 = useState([]), regions = _4[0], setRegions = _4[1];
    var _5 = useState([]), addressData = _5[0], setAddressData = _5[1];
    var _6 = useState([]), mailingAddressData = _6[0], setMailingAddressData = _6[1];
    var _7 = useState(''), mailingAddressQuery = _7[0], setMailingAddressQuery = _7[1]; // For the mailing address query
    var _8 = useState(false), showMailingAddress = _8[0], setShowMailingAddress = _8[1];
    var _9 = useState(''), selectedAddress = _9[0], setSelectedAddress = _9[1];
    var _10 = useState(''), selectedMailingAddress = _10[0], setSelectedMailingAddress = _10[1];
    var _11 = useState(false), addressLoading = _11[0], setAddressLoading = _11[1];
    var _12 = useState(false), mailingAddressLoading = _12[0], setMailingAddressLoading = _12[1];
    var _13 = useState(''), addressQuery = _13[0], setAddressQuery = _13[1]; // Holds the current input text for the query
    // Fetch publisher data by ID using react-query
    var _14 = useQuery(['publisher', id], function () { return fetchPublisherData(id, ky); }, { enabled: !!id }), publisherData = _14.data, error = _14.error, isLoading = _14.isLoading;
    var _15 = useQuery('regions', function () { return fetchRegions(ky); }, {
        onSuccess: function (data) {
            setRegions(data); // Set the regions state
        },
    }), _16 = _15.data, regionsData = _16 === void 0 ? [] : _16, regionsLoading = _15.isLoading;
    useEffect(function () {
        var _a, _b;
        if (publisherData) {
            setFormValues(publisherData);
            setSelectedAddress(((_a = publisherData.mainAddress) === null || _a === void 0 ? void 0 : _a.addressCode) || '');
            setSelectedMailingAddress(((_b = publisherData.mailingAddress) === null || _b === void 0 ? void 0 : _b.addressCode) || '');
        }
    }, [publisherData]);
    useEffect(function () {
        fetchAddress(ky, mailingAddressQuery)
            .then(function (addresses) {
            setMailingAddressData(addresses);
        })
            .catch(function (error) {
            console.error("Error fetching mailing addresses:", error);
            setMailingAddressData([]); // Clear addresses in case of an error
        });
    }, [mailingAddressQuery]);
    useEffect(function () {
        fetchAddress(ky, addressQuery)
            .then(function (publisherAddresses) {
            setAddressData(publisherAddresses);
        })
            .catch(function (error) {
            console.error("Error fetching addresses:", error);
            setAddressData([]); // Clear addresses in case of an error
        });
    }, [addressQuery]);
    var handleInputChange = function (e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        setFormValues(__assign(__assign({}, formValues), (_a = {}, _a[name] = value, _a)));
    };
    var handleAddressChange = function (e, type) {
        var _a = e.target, name = _a.name, value = _a.value;
        if (name == 'region') {
            var selectedRegion_1 = regions.find(function (region) { return region.id === value; });
            setFormValues(function (prevFormValues) {
                var _a;
                return (__assign(__assign({}, prevFormValues), (_a = {}, _a[type] = __assign(__assign({}, prevFormValues[type]), { region: selectedRegion_1 }), _a)));
            });
        }
        else {
            setFormValues(function (prevFormValues) {
                var _a, _b;
                return (__assign(__assign({}, prevFormValues), (_a = {}, _a[type] = __assign(__assign({}, prevFormValues[type]), (_b = {}, _b[name] = value, _b)), _a)));
            });
        }
    };
    var handleAddressSelection = function (selectedValue) { return __awaiter(void 0, void 0, void 0, function () {
        var addressDetails_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setSelectedAddress(selectedValue);
                    return [4 /*yield*/, fetchAddressCode(ky, selectedValue)];
                case 1:
                    addressDetails_1 = _a.sent();
                    setFormValues(function (prevFormValues) { return (__assign(__assign({}, prevFormValues), { mainAddress: {
                            addressCode: selectedValue,
                            street: addressDetails_1.street,
                            postalCode: addressDetails_1.postalCode,
                            municipality: addressDetails_1.municipality,
                            region: addressDetails_1.region || { id: '', name: '' },
                        } })); });
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    console.error("Error fetching address details:", error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleMailingAddressSelection = function (selectedValue) { return __awaiter(void 0, void 0, void 0, function () {
        var addressDetails_2, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    setSelectedMailingAddress(selectedValue);
                    return [4 /*yield*/, fetchAddressCode(ky, selectedValue)];
                case 1:
                    addressDetails_2 = _a.sent();
                    console.log('Fetched address:', addressDetails_2);
                    setFormValues(function (prevFormValues) { return (__assign(__assign({}, prevFormValues), { mailingAddress: {
                            addressCode: selectedValue,
                            street: addressDetails_2.street,
                            postalCode: addressDetails_2.postalCode,
                            municipality: addressDetails_2.municipality,
                            region: addressDetails_2.region || { id: '', name: '' }, // Add this field with a default value
                        } })); });
                    return [3 /*break*/, 3];
                case 2:
                    error_2 = _a.sent();
                    console.error("Error fetching mailing address details:", error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var handleContactChange = function (index, e) {
        var _a;
        var _b;
        var _c = e.target, name = _c.name, value = _c.value;
        var updatedContacts = __spreadArray([], (_b = formValues.contacts) !== null && _b !== void 0 ? _b : [], true);
        updatedContacts[index] = __assign(__assign({}, updatedContacts[index]), (_a = {}, _a[name] = value, _a));
        setFormValues(__assign(__assign({}, formValues), { contacts: updatedContacts }));
    };
    var debouncedSetAddressQuery = useCallback(debounce(function (inputValue) {
        setAddressQuery(inputValue);
    }, 300), []);
    var handleAddressInput = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setAddressLoading(true);
                    console.log(addressData.map(function (address) { return ({
                        value: address.addressCode,
                        label: address.address,
                    }); }));
                    console.log('Input value for address search:', inputValue);
                    return [4 /*yield*/, debouncedSetAddressQuery(inputValue)];
                case 1:
                    _a.sent();
                    setAddressLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var addContact = function () {
        // Here we initialize the new contact with a default type.
        setFormValues(__assign(__assign({}, formValues), { contacts: __spreadArray(__spreadArray([], (formValues.contacts || []), true), [
                { type: "EMAIL", value: "" } // Change "EMAIL" to a default type as needed
            ], false) }));
    };
    var removeContact = function (index) {
        var _a;
        var updatedContacts = (_a = formValues.contacts) === null || _a === void 0 ? void 0 : _a.filter(function (_, i) { return i !== index; });
        setFormValues(__assign(__assign({}, formValues), { contacts: updatedContacts }));
    };
    var handleAlternativeNameChange = function (index, e) {
        var _a;
        var _b = e.target, name = _b.name, value = _b.value;
        // Ensure alternativeNames is always treated as an array
        var updatedAlternativeNames = formValues.alternativeNames ? __spreadArray([], formValues.alternativeNames, true) : [];
        // Check if index is within bounds
        if (updatedAlternativeNames[index]) {
            updatedAlternativeNames[index] = __assign(__assign({}, updatedAlternativeNames[index]), (_a = {}, _a[name] = value, _a));
        }
        else {
            // Optionally handle the case where the index is out of bounds
            console.warn("Index ".concat(index, " is out of bounds for alternative names."));
        }
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: updatedAlternativeNames }));
    };
    var debouncedSetMailingAddressQuery = useCallback(debounce(function (inputValue) {
        setMailingAddressQuery(inputValue);
    }, 300), []);
    var handleMailingAddressInput = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setMailingAddressLoading(true);
                    return [4 /*yield*/, debouncedSetMailingAddressQuery(inputValue)];
                case 1:
                    _a.sent();
                    setMailingAddressLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var addAlternativeName = function () {
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: __spreadArray(__spreadArray([], (formValues.alternativeNames || []), true), [
                { name: '', nameSuffix: '', qualifier: '', type: 'PREVIOUS', nonPublic: false },
            ], false) }));
    };
    var removeAlternativeName = function (index) {
        var _a;
        var updatedAlternativeNames = (_a = formValues.alternativeNames) === null || _a === void 0 ? void 0 : _a.filter(function (_, i) { return i !== index; });
        setFormValues(__assign(__assign({}, formValues), { alternativeNames: updatedAlternativeNames }));
    };
    var toggleExpandCollapseAll = function () {
        setExpandAll(!expandAll);
        setAccordionStatus({
            general: !expandAll,
            address: !expandAll,
            contacts: !expandAll,
            alternativeNames: !expandAll,
            comments: !expandAll,
        });
    };
    var handleIco = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newErrors, aresData_1, error_3;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    newErrors = __assign({}, errors);
                    // Validate ICO
                    if (!formValues.companyNumber || formValues.companyNumber.trim() === '') {
                        newErrors.companyNumber = "".concat(formValues.legalForm ? 'Právnická osoba' : 'Fyzická osoba', " pot\u0159ebuje I\u010CO.");
                        setErrors(newErrors);
                        return [2 /*return*/];
                    }
                    else if (!/^\d{8}$/.test(formValues.companyNumber)) {
                        newErrors.companyNumber = 'IČO musí mít přesně 8 číslic.';
                        setErrors(newErrors);
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetchAresDataByCompanyNumber(ky, formValues.companyNumber)];
                case 2:
                    aresData_1 = _b.sent();
                    setFormValues(function (prevFormValues) { return (__assign(__assign({}, prevFormValues), { name: aresData_1.name, legalForm: aresData_1.legalForm || prevFormValues.legalForm, taxNumber: aresData_1.taxNumber || prevFormValues.taxNumber })); });
                    handleAddressSelection((_a = aresData_1.addressCode) !== null && _a !== void 0 ? _a : '');
                    return [3 /*break*/, 4];
                case 3:
                    error_3 = _b.sent();
                    console.error('Error fetching Ares data:', error_3);
                    setErrorMessage('Došlo k chybě při hledáni IČA.');
                    setErrorModalOpen(true);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var validateForm = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var newErrors = {
            mainAddress: {},
            mailingAddress: {},
            contacts: [],
            alternativeNames: []
        };
        // Main fields validation
        if (!formValues.name)
            newErrors.name = 'Název je povinný.';
        if (formValues.legalForm == 'LEGAL')
            setFormValues(__assign(__assign({}, formValues), { birthDate: '' }));
        if (!formValues.birthDate && formValues.legalForm == 'NATURAL') {
            newErrors.birthDate = 'Fyzická osoba potřebuje Datum narození.';
        }
        else {
            newErrors.birthDate = '';
        }
        if (!((_a = formValues.mainAddress) === null || _a === void 0 ? void 0 : _a.street))
            newErrors.mainAddress = __assign(__assign({}, newErrors.mainAddress), { street: 'Ulice je povinná.' });
        if (!((_b = formValues.mainAddress) === null || _b === void 0 ? void 0 : _b.municipality))
            newErrors.mainAddress = __assign(__assign({}, newErrors.mainAddress), { municipality: 'Obec je povinná.' });
        if (!((_c = formValues.mainAddress) === null || _c === void 0 ? void 0 : _c.postalCode))
            newErrors.mainAddress = __assign(__assign({}, newErrors.mainAddress), { postalCode: 'PSČ je povinné.' });
        if (!((_d = formValues.mainAddress) === null || _d === void 0 ? void 0 : _d.region.id))
            newErrors.mainAddress = __assign(__assign({}, newErrors.mainAddress), { region: 'Kraj je povinný.' });
        // Mailing Address validation
        if (showMailingAddress) {
            if (!((_e = formValues.mailingAddress) === null || _e === void 0 ? void 0 : _e.street))
                newErrors.mailingAddress = __assign(__assign({}, newErrors.mailingAddress), { street: 'Ulice doručení je povinná.' });
            if (!((_f = formValues.mailingAddress) === null || _f === void 0 ? void 0 : _f.municipality))
                newErrors.mailingAddress = __assign(__assign({}, newErrors.mailingAddress), { municipality: 'Obec doručení je povinná.' });
            if (!((_g = formValues.mailingAddress) === null || _g === void 0 ? void 0 : _g.postalCode))
                newErrors.mailingAddress = __assign(__assign({}, newErrors.mailingAddress), { postalCode: 'PSČ doručení je povinné.' });
            if (!((_h = formValues.mailingAddress) === null || _h === void 0 ? void 0 : _h.region.id))
                newErrors.mailingAddress = __assign(__assign({}, newErrors.mailingAddress), { region: 'Kraj doručení je povinný.' });
        }
        var entityType = formValues.legalForm === 'LEGAL' ? 'Právnická osoba' : 'Fyzická osoba';
        if (!formValues.companyNumber || formValues.companyNumber.trim() === '') {
            newErrors.companyNumber = "".concat(entityType, " pot\u0159ebuje I\u010CO.");
        }
        else if (!/^\d{8}$/.test(formValues.companyNumber)) {
            newErrors.companyNumber = 'IČO musí mít přesně 8 číslic.';
        }
        if (!formValues.taxNumber || formValues.taxNumber.trim() === '') {
            newErrors.taxNumber = "".concat(entityType, " pot\u0159ebuje DI\u010C.");
        }
        else if (
        // Check for format: exactly 2 letters followed by 2 to 13 digits
        !/^[A-Za-z]{2}\d{2,13}$/.test(formValues.taxNumber)) {
            newErrors.taxNumber = 'DIČ musí mít 2 písmena následovaná 2 až 13 číslicemi.';
        }
        // Contact validation
        newErrors.contacts = (formValues.contacts || []).map(function (contact) {
            var contactErrors = {};
            if (!contact.value)
                contactErrors.value = 'Kontakt je povinný.';
            else if (contact.type === 'EMAIL' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact.value))
                contactErrors.value = 'Hodnota kontaktu typu E-mail není formálně platnou e-mailovou adresou.';
            else if (contact.type === 'WEB' && !/^https?:\/\/[^\s$.?#].[^\s]*$/.test(contact.value))
                contactErrors.value = 'Hodnota kontaktu typu Web není formálně platným URL.';
            else if (contact.type === 'PHONE' && !/^\+?[0-9\s\-]{7,15}$/.test(contact.value))
                contactErrors.value = 'Hodnota kontaktu typu Telefon má méně než 9 číslic.';
            else if (contact.type === 'DATABOX' && (contact.value.length < 6 || contact.value.length > 12))
                contactErrors.value = 'Hodnota kontaktu typu Datová schránka má méně než 6 nebo více než 12 znaků.';
            if (!contact.type)
                contactErrors.type = 'Typ kontaktu je povinný.';
            return contactErrors;
        });
        // Alternative Name validation
        newErrors.alternativeNames = (formValues.alternativeNames || []).map(function (altName) {
            var altNameErrors = {};
            if (!altName.name)
                altNameErrors.name = 'Alternativní název je povinný.';
            return altNameErrors;
        });
        // Set errors in state
        setErrors(newErrors);
        var hasErrors = Object.values(newErrors).some(function (error) {
            return Array.isArray(error) ? error.some(function (e) { return Object.keys(e).length > 0; }) : Object.keys(error || {}).length > 0;
        });
        return !hasErrors;
    };
    var handleSubmit = function (e, status) { return __awaiter(void 0, void 0, void 0, function () {
        var updatedFormValues, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    e.preventDefault();
                    updatedFormValues = __assign(__assign({}, formValues), { status: status });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    // Set form values for UI consistency (but this won’t affect updatePublisher in this call)
                    setFormValues(updatedFormValues);
                    // Log and use updatedFormValues directly to ensure it has the latest status
                    return [4 /*yield*/, updatePublisher(ky, id, updatedFormValues)];
                case 2:
                    // Log and use updatedFormValues directly to ensure it has the latest status
                    _a.sent();
                    // Navigate back after a successful submission
                    history.goBack();
                    return [3 /*break*/, 4];
                case 3:
                    error_4 = _a.sent();
                    setErrorMessage('Došlo k chybě při editaci nakladatele.');
                    setErrorModalOpen(true);
                    console.error('Error updating publisher:', error_4);
                    console.log('Submitting data:', JSON.stringify(updatedFormValues, null, 2));
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var footer = (React.createElement(PaneFooter, { renderStart: React.createElement(Button, { buttonStyle: "primary", type: "submit", onClick: function (e) {
                if (validateForm()) {
                    if (status == 'ACTIVE' || status == 'EDIT')
                        handleSubmit(e, 'ACTIVE');
                    if (status == 'REJECTED')
                        handleSubmit(e, 'REJECTED');
                }
            } }, status === 'EDIT' ? 'Uložit' : status === 'ACTIVE' ? 'Schválit' : 'Zamítnout'), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function (e) {
                history.goBack();
            } }, "Zru\u0161it") }));
    return (React.createElement("div", { style: { display: 'flex', height: '93.5vh' } },
        React.createElement(Pane, { defaultWidth: "fill", paneTitle: status === 'EDIT' ? "Úprava nakladatele" : status === 'ACTIVE' ? 'Schválení nakladatele' : 'Zamítnutí nakladatele', footer: footer },
            React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                React.createElement("div", null, errorMessage),
                React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 12, style: { display: 'flex', justifyContent: 'flex-end' } },
                    React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? 'Sbalit všechny' : 'Rozbalit všechny'))),
            React.createElement("form", { onSubmit: function (e) { return handleSubmit(e, 'ACTIVE'); } },
                React.createElement(AccordionSet, null,
                    React.createElement(Accordion, { label: "Z\u00E1kladn\u00ED informace", open: accordionStatus.general, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { general: !accordionStatus.general }));
                        } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6, style: { display: 'flex', alignItems: 'flex-end' } },
                                React.createElement(TextField, { label: "I\u010CO", name: "companyNumber", required: true, value: formValues.companyNumber, error: errors.companyNumber, onChange: handleInputChange }),
                                React.createElement(Button, { buttonStyle: "primary", onClick: handleIco, style: { marginLeft: '16px', marginBottom: errors.companyNumber ? '2.6rem' : '1rem' } }, "Na\u010D\u00EDst dle I\u010CO z rejst\u0159\u00EDku")),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "N\u00E1zev", name: "name", value: formValues.name, onChange: handleInputChange, required: true, error: errors.name }))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "Dodatek", name: "nameSuffix", value: formValues.nameSuffix, onChange: handleInputChange })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Select, { label: "Forma", name: "legalForm", value: formValues.legalForm, onChange: handleInputChange },
                                    React.createElement("option", { value: "LEGAL" }, "Pr\u00E1vnick\u00E1 osoba"),
                                    React.createElement("option", { value: "NATURAL" }, "Fyzick\u00E1 osoba")))),
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "\u010C\u00EDslo DI\u010C", name: "taxNumber", required: true, error: errors.taxNumber, value: formValues.taxNumber, onChange: handleInputChange })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Datepicker, { label: "Datum narozen\u00ED", value: formValues.birthDate, error: errors.birthDate, disabled: formValues.legalForm == 'LEGAL', onChange: function (e, formattedDate, dateString) {
                                        return setFormValues(__assign(__assign({}, formValues), { birthDate: dateString || '' }));
                                    }, backendDateStandard: "YYYY-MM-DD", dateFormat: "DD/MM/YYYY" })))),
                    React.createElement(Accordion, { label: "Adresa", open: accordionStatus.address, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { address: !accordionStatus.address })); } },
                        React.createElement(Row, null,
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Selection, { label: "Adresa", name: "publisherAddress", value: selectedAddress, disabled: addressLoading, onChange: handleAddressSelection, asyncFilter: true, onFilter: handleAddressInput, dataOptions: __spreadArray([], addressData.map(function (address) { return ({
                                        value: address.addressCode,
                                        label: address.address,
                                    }); }), true) })),
                            React.createElement(Col, { xs: 6, style: { display: 'flex', alignItems: 'center' } },
                                React.createElement(Checkbox, { label: "Povolit zad\u00E1n\u00ED hlavn\u00ED adresy", checked: enableMainAddress, onChange: function () { return setEnableMainAddress(!enableMainAddress); } })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Select, { label: "Kraj", name: "region", required: true, value: (_b = formValues.mainAddress) === null || _b === void 0 ? void 0 : _b.region.id, onChange: function (e) { return handleAddressChange(e, 'mainAddress'); }, error: (_c = errors.mainAddress) === null || _c === void 0 ? void 0 : _c.region, disabled: !enableMainAddress, dataOptions: (regions || []).map(function (region) { return ({
                                        value: region.id,
                                        label: region.name,
                                    }); }) })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "Ulice", name: "street", value: (_d = formValues.mainAddress) === null || _d === void 0 ? void 0 : _d.street, onChange: function (e) { return handleAddressChange(e, 'mainAddress'); }, error: (_e = errors.mainAddress) === null || _e === void 0 ? void 0 : _e.street, disabled: !enableMainAddress, required: true })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "PS\u010C", name: "postalCode", value: (_f = formValues.mainAddress) === null || _f === void 0 ? void 0 : _f.postalCode, error: (_g = errors.mainAddress) === null || _g === void 0 ? void 0 : _g.postalCode, disabled: !enableMainAddress, onChange: function (e) { return handleAddressChange(e, 'mainAddress'); }, required: true })),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "Obec", name: "municipality", value: (_h = formValues.mainAddress) === null || _h === void 0 ? void 0 : _h.municipality, error: (_j = errors.mainAddress) === null || _j === void 0 ? void 0 : _j.municipality, disabled: !enableMainAddress, onChange: function (e) { return handleAddressChange(e, 'mainAddress'); }, required: true }))),
                        React.createElement(Checkbox, { label: "Li\u0161\u00ED se doru\u010Dovac\u00ED adresa?", checked: showMailingAddress, onChange: function () { return setShowMailingAddress(!showMailingAddress); } }),
                        showMailingAddress && (React.createElement(React.Fragment, null,
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Selection, { label: "Doru\u010Dovac\u00ED adresa", name: "mailingAddress", value: selectedMailingAddress, disabled: mailingAddressLoading, onChange: handleMailingAddressSelection, asyncFilter: true, onFilter: handleMailingAddressInput, dataOptions: __spreadArray([], mailingAddressData.map(function (address) { return ({
                                            value: address.addressCode,
                                            label: address.address,
                                        }); }), true) })),
                                React.createElement(Col, { xs: 6, style: { display: 'flex', alignItems: 'center' } },
                                    React.createElement(Checkbox, { label: "Povolit zad\u00E1n\u00ED doru\u010Dovac\u00ED adresy ru\u010Dne", checked: enableMailingAddress, onChange: function () { return setEnableMailingAddress(!enableMailingAddress); } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(Select, { label: "Kraj", name: "region", required: true, value: (_k = formValues.mailingAddress) === null || _k === void 0 ? void 0 : _k.region.id, onChange: function (e) { return handleAddressChange(e, 'mailingAddress'); }, error: (_l = errors.mailingAddress) === null || _l === void 0 ? void 0 : _l.region, disabled: !enableMailingAddress, dataOptions: (regions || []).map(function (region) { return ({
                                            value: region.id,
                                            label: region.name,
                                        }); }) })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextField, { label: "Ulice", name: "street", value: (_m = formValues.mailingAddress) === null || _m === void 0 ? void 0 : _m.street, error: (_o = errors.mailingAddress) === null || _o === void 0 ? void 0 : _o.street, required: true, disabled: !enableMailingAddress, onChange: function (e) { return handleAddressChange(e, 'mailingAddress'); } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextField, { label: "PS\u010C", name: "postalCode", value: (_p = formValues.mailingAddress) === null || _p === void 0 ? void 0 : _p.postalCode, error: (_q = errors.mailingAddress) === null || _q === void 0 ? void 0 : _q.postalCode, required: true, disabled: !enableMailingAddress, onChange: function (e) { return handleAddressChange(e, 'mailingAddress'); } })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextField, { label: "Obec", name: "municipality", value: (_r = formValues.mailingAddress) === null || _r === void 0 ? void 0 : _r.municipality, error: (_s = errors.mailingAddress) === null || _s === void 0 ? void 0 : _s.municipality, required: true, disabled: !enableMailingAddress, onChange: function (e) { return handleAddressChange(e, 'mailingAddress'); } })))))),
                    React.createElement(Accordion, { label: "Kontakty", open: accordionStatus.contacts, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { contacts: !accordionStatus.contacts })); } }, (_t = formValues.contacts) === null || _t === void 0 ? void 0 :
                        _t.map(function (contact, index) { return (React.createElement(Row, { key: index },
                            React.createElement(Col, { xs: 4 },
                                React.createElement(Select, { label: "Typ kontaktu", name: "type", value: contact.type, onChange: function (e) { return handleContactChange(index, e); }, error: errors.contacts && errors.contacts[index] && errors.contacts[index].type },
                                    React.createElement("option", { value: "EMAIL" }, "E-mail"),
                                    React.createElement("option", { value: "WEB" }, "Web"),
                                    React.createElement("option", { value: "PHONE" }, "Telefon"),
                                    React.createElement("option", { value: "DATABOX" }, "Datov\u00E1 schr\u00E1nka"))),
                            React.createElement(Col, { xs: 6 },
                                React.createElement(TextField, { label: "Kontakt", name: "value", value: contact.value, onChange: function (e) { return handleContactChange(index, e); }, error: errors.contacts && errors.contacts[index] && errors.contacts[index].value })),
                            React.createElement(Col, { xs: 2 },
                                React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeContact(index); }, style: { marginTop: '25px' } }, "Odstranit")))); }),
                        React.createElement(Button, { onClick: addContact }, "P\u0159idat kontakt")),
                    React.createElement(Accordion, { label: "Alternativn\u00ED n\u00E1zvy", open: accordionStatus.alternativeNames, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { alternativeNames: !accordionStatus.alternativeNames }));
                        } }, (_u = formValues.alternativeNames) === null || _u === void 0 ? void 0 :
                        _u.map(function (alternativeName, index) {
                            var _a, _b, _c;
                            return (React.createElement(Row, { key: index },
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(TextField, { label: "Alternativn\u00ED n\u00E1zev", name: "name", value: alternativeName.name, onChange: function (e) { return handleAlternativeNameChange(index, e); }, required: true, error: errors.alternativeNames && ((_a = errors.alternativeNames[index]) === null || _a === void 0 ? void 0 : _a.name) })),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(TextField, { label: "P\u0159\u00EDpona n\u00E1zvu", name: "nameSuffix", value: alternativeName.nameSuffix || '', onChange: function (e) { return handleAlternativeNameChange(index, e); }, required: true, error: errors.alternativeNames && ((_b = errors.alternativeNames[index]) === null || _b === void 0 ? void 0 : _b.nameSuffix) })),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(TextField, { label: "Kvalifik\u00E1tor", name: "qualifier", value: alternativeName.qualifier || '', onChange: function (e) { return handleAlternativeNameChange(index, e); }, error: errors.alternativeNames && ((_c = errors.alternativeNames[index]) === null || _c === void 0 ? void 0 : _c.qualifier) })),
                                React.createElement(Col, { xs: 4 },
                                    React.createElement(Select, { label: "Typ", name: "type", value: alternativeName.type || '', onChange: function (e) { return handleAlternativeNameChange(index, e); } },
                                        React.createElement("option", { value: "" }, "-"),
                                        React.createElement("option", { value: "PREVIOUS" }, "P\u0159edchoz\u00ED n\u00E1zev"),
                                        React.createElement("option", { value: "IMPRINT" }, "Imprint"),
                                        React.createElement("option", { value: "ABBREVATION" }, "Zkratka"),
                                        React.createElement("option", { value: "ORGANIZATIONAL_UNIT" }, "Organiza\u010Dn\u00ED jednotka"),
                                        React.createElement("option", { value: "FOREIGN_LANGUAGE" }, "Cizojazy\u010Dn\u00FD ekvivalent"))),
                                React.createElement(Col, { xs: 2 },
                                    React.createElement(Checkbox, { label: "Neve\u0159ejn\u00E9", checked: alternativeName.nonPublic, onChange: function () {
                                            var _a;
                                            return setFormValues(__assign(__assign({}, formValues), { alternativeNames: (_a = formValues.alternativeNames) === null || _a === void 0 ? void 0 : _a.map(function (altName, i) {
                                                    return i === index ? __assign(__assign({}, altName), { nonPublic: !altName.nonPublic }) : altName;
                                                }) }));
                                        } })),
                                React.createElement(Col, { xs: 12 },
                                    React.createElement(Button, { buttonStyle: "danger", onClick: function () { return removeAlternativeName(index); }, style: { marginTop: '10px' } }, "Odstranit alternativn\u00ED n\u00E1zev"))));
                        }),
                        React.createElement(Button, { onClick: addAlternativeName }, "P\u0159idat alternativn\u00ED n\u00E1zev")),
                    React.createElement(Accordion, { label: "Intern\u00ED pozn\u00E1mka", open: accordionStatus.comments, onToggle: function () {
                            return setAccordionStatus(__assign(__assign({}, accordionStatus), { comments: !accordionStatus.comments }));
                        } },
                        React.createElement(TextArea, { label: "Pozn\u00E1mka", name: "internalComment", value: formValues.internalComment, onChange: handleInputChange })))))));
};
export default PublisherEdit;
