import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { useOkapiKy } from '@folio/stripes/core';
import { Button, Pane, MenuSection, IconButton } from '@folio/stripes/components';
import TableComponent from '../../components/TableComponent';
import NavComponent from '../../components/NavComponent';
import PublisherRegistrationsDetail from './PublisherRegistrationsDetail'; // Adjusted import path
import { StripesConnectedSource } from '@folio/stripes/smart-components';
import { fetchPublishers } from '../../api/publisherService';
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var PublisherRegistrationsList = function () {
    var history = useHistory();
    var ky = useOkapiKy();
    var _a = useState(null), selectedPublisherRegister = _a[0], setSelectedPublisherRegister = _a[1];
    var _b = useState({
        name: '',
        ico: '',
        startDate: undefined,
        endDate: undefined,
        status: ['REQUEST']
    }), filters = _b[0], setFilters = _b[1];
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var publisherId = queryParams.get('detail');
        if (publisherId) {
            fetchPublishers(ky, { name: '', companyNumber: '', status: [] }).then(function (publishers) {
                var publisher = publishers.find(function (p) { return p.id === publisherId; });
                if (publisher) {
                    setSelectedPublisherRegister(publisher);
                    // Ensure filters are preserved when opening detail
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var _c = useState(true), filterPaneIsVisible = _c[0], setFilterPaneIsVisible = _c[1];
    var _d = useState(''), searchTerm = _d[0], setSearchTerm = _d[1];
    var isAnyFilterActive = function () {
        return true;
    };
    var _e = useQuery(['publishers', filters], function () { return fetchPublishers(ky, filters); }, {
        enabled: isAnyFilterActive(),
        staleTime: 0,
        cacheTime: 0,
    }), _f = _e.data, publisherRegistrationsData = _f === void 0 ? [] : _f, isLoading = _e.isLoading, isError = _e.isError;
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var statusTranslations = {
        REQUEST: 'Žádost',
        REJECTED: 'Odmítnuto',
        ACTIVE: 'Aktivní',
        CLOSING: 'Uzavírání',
        CLOSED: 'Uzavřeno',
    };
    var formatDateToEuropean = function (dateString) {
        var date = new Date(dateString);
        var day = String(date.getDate()).padStart(2, '0');
        var month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        var year = date.getFullYear();
        return "".concat(day, ".").concat(month, ".").concat(year);
    };
    var columns = [
        {
            name: 'Nakladatel',
            key: 'name',
            clickable: true,
            onClick: function (item) {
                // Append filters to the URL when navigating to detail
                var currentParams = new URLSearchParams(location.search);
                // Remove any existing 'detail' query parameter
                currentParams.delete('detail');
                history.push({
                    search: "".concat(currentParams.toString(), "&detail=").concat(item.id),
                });
                setSelectedPublisherRegister(item);
            },
        },
        {
            name: 'Doplňek k názvu',
            key: 'nameSuffix',
        },
        {
            name: 'Kvalifikátor',
            key: 'qualifier',
        },
        {
            name: 'IČO',
            key: 'companyNumber',
        },
        {
            name: 'Datum narození',
            key: 'birthDate',
            cellRenderer: function (item) {
                return item.birthDate ? formatDateToEuropean(item.birthDate) : 'N/A';
            }, // Return 'N/A' if birthDate is undefined
        },
        {
            name: 'Stav',
            key: 'status',
            formatter: function (item) { return statusTranslations[item.status]; }, // Use Czech translations here
        },
    ];
    var source = new StripesConnectedSource({}, null);
    source.update = function () { };
    source.records = function () { return publisherRegistrationsData; };
    source.resultCount = function () { return publisherRegistrationsData.length; };
    source.totalCount = function () { return publisherRegistrationsData.length; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isAnyFilterActive(); };
    source.failure = function () { return isError ? { message: 'Error loading data' } : null; };
    source.failureMessage = function () { return 'Error loading data'; };
    source.fetchMore = function () { };
    source.fetchByBrowsePoint = function () { };
    source.fetchOffset = function () { };
    source.successfulMutations = function () { return []; };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: 'flex', height: '93.5vh' } },
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace", lastMenu: filterPaneIsVisible ? React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? 'Hide filters' : 'Show filters', style: { marginLeft: 'auto' } }) : React.createElement(React.Fragment, null) },
                React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                    React.createElement(NavComponent, null)))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? 'fill' : '100%', paneTitle: "Seznam registrac\u00ED nakladatele", paneSub: publisherRegistrationsData.length > 0 ? "Nalezeno ".concat(publisherRegistrationsData.length, " z\u00E1znam\u016F") : "Zadejte vyhledávací kritéria pro zahájení vyhledávání", firstMenu: !filterPaneIsVisible ? React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? 'Hide filters' : 'Show filters', style: { marginLeft: 'auto' } }) : React.createElement(React.Fragment, null) },
                React.createElement(TableComponent, { data: publisherRegistrationsData, columns: columns, pageSize: 30, hasDataFetched: isAnyFilterActive, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source, searchTerm: searchTerm })),
            selectedPublisherRegister ? (React.createElement(Pane, { defaultWidth: "35%", paneTitle: "Detail Registrace nakladatele", onClose: function () {
                    setSelectedPublisherRegister(null);
                    var queryParams = new URLSearchParams(location.search);
                    queryParams.delete('detail');
                    history.replace({ search: queryParams.toString() }); // Remove only the detail parameter
                }, dismissible: true, actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return history.push("/brpublishers/publishers/approve/".concat(selectedPublisherRegister.id)); }, fullWidth: true }, "Schv\u00E1lit"),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return history.push("/brpublishers/publishers/reject/".concat(selectedPublisherRegister.id)); }, fullWidth: true }, "Zam\u00EDtnout"),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { }, fullWidth: true }, "Obnovit")));
                } }, selectedPublisherRegister ? (React.createElement(PublisherRegistrationsDetail, { publisher: selectedPublisherRegister })) : (React.createElement("div", null, "No details available")))) : null)));
};
export default PublisherRegistrationsList;
