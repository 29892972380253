var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// Reusable function to get headers
var getDefaultHeaders = function () { return ({
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
    Expires: '0',
}); };
// Reusable function to get JSON headers with additional tenant info
var getJsonHeaders = function () { return ({
    'Content-Type': 'application/json',
    'x-okapi-tenant': 'tritius',
    Pragma: 'no-cache',
    Expires: '0',
}); };
export var fetchOrganizations = function (ky, filters) { return __awaiter(void 0, void 0, void 0, function () {
    var name_1, archived, queryParts, queryParam, response, data, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 4]);
                name_1 = filters.name, archived = filters.archived;
                queryParts = [];
                if (name_1)
                    queryParts.push("name=\"*".concat(name_1, "*\""));
                if (archived)
                    queryParts.push("archived=true");
                else
                    queryParts.push("archived=false");
                queryParam = queryParts.length > 0 ? "query=".concat(encodeURIComponent(queryParts.join(' and '))) : '';
                return [4 /*yield*/, ky.get("organizations?".concat(queryParam), {
                        headers: getDefaultHeaders(),
                    })];
            case 1:
                response = _a.sent();
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data.organizations];
            case 3:
                error_1 = _a.sent();
                console.error('Fetch error:', error_1);
                throw error_1;
            case 4: return [2 /*return*/];
        }
    });
}); };
export var fetchOrganizationData = function (id, ky) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ky.get("organizations/".concat(id), {
                        headers: getJsonHeaders(),
                    })];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    throw new Error("Error: ".concat(response.statusText));
                }
                return [2 /*return*/, response.json()];
            case 2:
                error_2 = _a.sent();
                console.error('Fetch error:', error_2);
                throw error_2;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var archiveOrganization = function (ky, organizationId) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ky.post("organizations/".concat(organizationId, "/archive"), {
                        headers: getDefaultHeaders(),
                    })];
            case 1:
                response = _a.sent();
                if (!response.ok)
                    throw new Error('Failed to archive organization');
                return [2 /*return*/, response.json()];
            case 2:
                error_3 = _a.sent();
                console.error('Archive error:', error_3);
                throw error_3;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var restoreOrganization = function (ky, organizationId) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ky.post("organizations/".concat(organizationId, "/restore"), {
                        headers: getDefaultHeaders(),
                    })];
            case 1:
                response = _a.sent();
                if (!response.ok)
                    throw new Error('Failed to restore organization');
                return [2 /*return*/, response.json()];
            case 2:
                error_4 = _a.sent();
                console.error('Restore error:', error_4);
                throw error_4;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var updateOrganization = function (ky, id, organizationData) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, ky.put("organizations/".concat(id), {
                        json: organizationData,
                        headers: getJsonHeaders(),
                    })];
            case 1:
                response = _a.sent();
                if (!response.ok)
                    throw new Error("Error: ".concat(response.statusText));
                return [2 /*return*/, response.json()];
            case 2:
                error_5 = _a.sent();
                console.error('Update error:', error_5);
                throw error_5;
            case 3: return [2 /*return*/];
        }
    });
}); };
export var createOrganization = function (ky, name) { return __awaiter(void 0, void 0, void 0, function () {
    var newOrganization, response, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                newOrganization = {
                    name: name,
                    sourceId: '', // Assign a default sourceId or adjust this based on your needs
                    archived: false, // Set to false by default
                };
                return [4 /*yield*/, ky.post('organizations', {
                        json: newOrganization,
                        headers: __assign(__assign({}, getJsonHeaders()), getDefaultHeaders()),
                    })];
            case 1:
                response = _a.sent();
                if (!response.ok) {
                    throw new Error("Failed to create organization: ".concat(response.statusText));
                }
                return [2 /*return*/, response.json()];
            case 2:
                error_6 = _a.sent();
                console.error('Creation error:', error_6);
                throw error_6;
            case 3: return [2 /*return*/];
        }
    });
}); };
