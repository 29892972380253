var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { useOkapiKy } from '@folio/stripes/core';
import { Button, TextField, Pane, MenuSection, Accordion, Checkbox, IconButton, AccordionSet, Icon, ConfirmationModal } from '@folio/stripes/components';
import TableComponent from '../../components/TableComponent';
import NavComponent from '../../components/NavComponent';
import OrganizationDetail from './OrganizationDetail';
import { StripesConnectedSource } from '@folio/stripes/smart-components';
import { archiveOrganization, fetchOrganizations, restoreOrganization } from '../../api/organizationService';
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var OrganizationList = function () {
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var _a = useState(false), isModalOpen = _a[0], setIsModalOpen = _a[1];
    var _b = useState(null), organizationToArchive = _b[0], setOrganizationToArchive = _b[1];
    var _c = useState(null), archiveMessage = _c[0], setArchiveMessage = _c[1];
    var _d = useState(''), nameFilter = _d[0], setNameFilter = _d[1];
    var _e = useState(false), archivedFilter = _e[0], setArchivedFilter = _e[1];
    var _f = useState(null), selectedOrganization = _f[0], setSelectedOrganization = _f[1];
    var _g = useState({
        name: '',
        archived: false,
    }), filters = _g[0], setFilters = _g[1];
    var statusTranslations = {
        ARCHIVED: 'Archivováno',
    };
    var _h = useState(true), filterPaneIsVisible = _h[0], setFilterPaneIsVisible = _h[1];
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var organizationId = queryParams.get('detail');
        setFilters({
            name: queryParams.get('name') || '',
            archived: queryParams.get('archived') === 'true', // Parse archived as a boolean
        });
        setNameFilter(queryParams.get('name') || '');
        setArchivedFilter(queryParams.get('archived') === 'true');
        if (organizationId) {
            fetchOrganizations(ky, { name: '', archived: false }).then(function (organizations) {
                var organization = organizations.find(function (p) { return p.id === organizationId; });
                if (organization) {
                    setSelectedOrganization(organization);
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var updateUrlWithFilters = function (filters) {
        var params = new URLSearchParams();
        if (filters.name)
            params.set('name', filters.name);
        if (filters.archived !== undefined)
            params.set('archived', filters.archived.toString());
        history.push({ search: params.toString() });
    };
    var handleClearFilters = function () {
        setNameFilter('');
        setArchivedFilter(false);
        setFilters({
            name: '',
            archived: false,
        });
        history.push({ search: '' });
    };
    var handleRestoreClick = function () { return __awaiter(void 0, void 0, void 0, function () {
        var error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!selectedOrganization) return [3 /*break*/, 4];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, restoreOrganization(ky, selectedOrganization.id)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _a.sent();
                    console.error('Error archiving organization:', error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var isAnyFilterActive = function () {
        return (!!filters.name ||
            filters.archived);
    };
    var _j = useQuery(['organizations', filters], function () { return fetchOrganizations(ky, filters); }, {
        enabled: isAnyFilterActive(),
        staleTime: 0,
        cacheTime: 0,
    }), _k = _j.data, organizationData = _k === void 0 ? [] : _k, isLoading = _j.isLoading, isError = _j.isError;
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var openArchiveModal = function (organization) {
        setOrganizationToArchive(organization);
        setArchiveMessage(null); // Reset message on new archive attempt
        setIsModalOpen(true);
    };
    var handleArchiveClick = function (organization) {
        openArchiveModal(organization);
    };
    var columns = [
        {
            name: 'Jméno',
            key: 'name',
            clickable: true,
            onClick: function (item) {
                var _a;
                var currentParams = new URLSearchParams(location.search);
                currentParams.delete('detail');
                currentParams.set('detail', (_a = item.id) !== null && _a !== void 0 ? _a : '');
                history.push({
                    search: "".concat(currentParams.toString(), "&detail=").concat(item.id),
                });
                setSelectedOrganization(item);
            },
        },
        {
            name: 'Zdroj',
            key: 'source',
        },
        {
            name: 'Archivováno',
            key: 'archived',
        },
    ];
    var source = new StripesConnectedSource({}, null);
    source.update = function () { };
    source.records = function () { return organizationData; };
    source.resultCount = function () { return organizationData.length; };
    source.totalCount = function () { return organizationData.length; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isAnyFilterActive(); };
    source.failure = function () { return isError ? { message: 'Error loading data' } : null; };
    source.failureMessage = function () { return 'Error loading data'; };
    source.fetchMore = function () { };
    source.fetchByBrowsePoint = function () { };
    source.fetchOffset = function () { };
    source.successfulMutations = function () { return []; };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: 'flex', height: '93.5vh' } },
            React.createElement(ConfirmationModal, { open: isModalOpen, heading: archiveMessage ? "Výsledek archivace" : "Potvrďte archivaci", message: archiveMessage || "Opravdu chcete archivovat organizaci \"".concat(organizationToArchive === null || organizationToArchive === void 0 ? void 0 : organizationToArchive.name, "\"?"), onConfirm: archiveMessage ? function () { return setIsModalOpen(false); } : function () { return __awaiter(void 0, void 0, void 0, function () {
                    var error_2;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!organizationToArchive) return [3 /*break*/, 4];
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, archiveOrganization(ky, organizationToArchive.id)];
                            case 2:
                                _a.sent();
                                setArchiveMessage("Organizace byla úspěšně archivována."); // Success message
                                return [3 /*break*/, 4];
                            case 3:
                                error_2 = _a.sent();
                                console.error('Error archiving organization:', error_2);
                                setArchiveMessage("Archivace se nezdařila."); // Failure message
                                return [3 /*break*/, 4];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); }, onCancel: function () { return setIsModalOpen(false); }, confirmLabel: archiveMessage ? "OK" : "Archivovat", cancelLabel: !archiveMessage && "Zrušit", buttonStyle: "primary" }),
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace & filtrov\u00E1ni", lastMenu: filterPaneIsVisible ? React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? 'Hide filters' : 'Show filters', style: { marginLeft: 'auto' } }) : React.createElement(React.Fragment, null) },
                React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                    React.createElement(NavComponent, null),
                    React.createElement(Button, { buttonStyle: "none", id: "clickable-reset-all", disabled: !isAnyFilterActive(), marginBottom0: true, onClick: handleClearFilters },
                        React.createElement(Icon, { icon: "times-circle-solid" }, "Resetovat v\u0161echno")),
                    React.createElement(AccordionSet, null,
                        React.createElement(Accordion, { label: "Jm\u00E9no", separator: false },
                            React.createElement(TextField, { placeholder: "Jm\u00E9no...", value: nameFilter, onChange: function (e) { return setNameFilter(e.target.value); } }),
                            React.createElement(Button, { onClick: function () {
                                    setFilters(__assign(__assign({}, filters), { name: nameFilter }));
                                    updateUrlWithFilters(__assign(__assign({}, filters), { name: nameFilter, archived: archivedFilter }));
                                }, marginBottom0: true }, "Aplikovat")),
                        React.createElement(Accordion, { label: "Archivov\u00E1no", id: "archived-filter", separator: false },
                            React.createElement(Checkbox, { label: 'Archivov\u00E1no', checked: archivedFilter, onChange: function () {
                                    setArchivedFilter(!archivedFilter);
                                    setFilters(__assign(__assign({}, filters), { archived: !archivedFilter }));
                                    updateUrlWithFilters(__assign(__assign({}, filters), { archived: !archivedFilter }));
                                } })))))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? 'fill' : '100%', paneTitle: React.createElement(React.Fragment, null,
                    React.createElement("span", null, "Seznam organizac\u00ED")), paneSub: organizationData.length > 0 ? "Nalezeno ".concat(organizationData.length, " z\u00E1znam\u016F") : "Zadejte vyhledávací kritéria pro zahájení vyhledávání", actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                onToggle();
                                history.push('/brusers/organizations/create');
                            } }, "Nov\u00FD")));
                }, firstMenu: !filterPaneIsVisible ? React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? 'Hide filters' : 'Show filters', style: { marginLeft: 'auto' } }) : React.createElement(React.Fragment, null) },
                React.createElement(TableComponent, { data: organizationData, columns: columns, pageSize: 30, hasDataFetched: isAnyFilterActive, searchTerm: nameFilter, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source })),
            selectedOrganization ? (React.createElement(Pane, { defaultWidth: "35%", paneTitle: "Detail nakladatele", onClose: function () {
                    setSelectedOrganization(null);
                    var queryParams = new URLSearchParams(location.search);
                    queryParams.delete('detail');
                    history.replace({ search: queryParams.toString() }); // Remove only the detail parameter
                }, dismissible: true, actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return history.push("/brusers/organizations/edit/".concat(selectedOrganization.id)); }, fullWidth: true }, "Zm\u011Bnit"),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return handleArchiveClick(selectedOrganization); }, fullWidth: true }, "Archivovat"),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: handleRestoreClick, fullWidth: true }, "Obnovit")));
                } },
                React.createElement(OrganizationDetail, { organization: selectedOrganization, setSelectedOrganization: setSelectedOrganization }))) : null)));
};
export default OrganizationList;
