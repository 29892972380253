var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { useOkapiKy } from '@folio/stripes/core';
import { Button, TextField, Pane, MenuSection, Accordion, IconButton, AccordionSet, Icon, Selection } from '@folio/stripes/components';
import TableComponent from '../../components/TableComponent';
import NavComponent from '../../components/NavComponent';
import UserDetail from './UserDetail';
import { StripesConnectedSource } from '@folio/stripes/smart-components';
import { fetchUserOrganizations, fetchUsers } from '../../api/usersService';
import { debounce } from 'lodash';
import { fetchOrganizationData, fetchOrganizations } from '../../api/organizationService';
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 0,
            cacheTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
        },
    },
});
var UsersList = function () {
    var history = useHistory();
    var location = useLocation();
    var ky = useOkapiKy();
    var _a = useState({}), userOrganizations = _a[0], setUserOrganizations = _a[1];
    var _b = useState(''), searchFilter = _b[0], setSearchFilter = _b[1];
    var _c = useState(''), organizationFilter = _c[0], setOrganizationFilter = _c[1];
    // const [archivedFilter, setArchivedFilter] = useState<boolean>(false);
    var _d = useState(null), selectedUser = _d[0], setSelectedUser = _d[1];
    var _e = useState({
        search: '',
        organization: '',
        // archived: false,
    }), filters = _e[0], setFilters = _e[1];
    var _f = useState(''), selectedOrg = _f[0], setSelectedOrg = _f[1]; // Holds selected user ID
    var _g = useState(false), orgLoading = _g[0], setOrgLoading = _g[1]; // Loading state for users
    var _h = useState(''), orgQuery = _h[0], setOrgQuery = _h[1]; // Holds the current input text for user search
    var _j = useState(''), name = _j[0], setName = _j[1];
    var _k = useState([]), organizations = _k[0], setOrganizations = _k[1];
    var debouncedSetUserQuery = useCallback(debounce(function (inputValue) {
        setOrgQuery(inputValue);
    }, 300), []);
    // Handle input changes in the Selection component for users
    var handleOrganizationInput = function (inputValue) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setOrgLoading(true);
                    setName(inputValue);
                    return [4 /*yield*/, debouncedSetUserQuery(inputValue)];
                case 1:
                    _a.sent(); // Use inputValue for debouncing
                    setOrgLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleOrganizationSelection = function (selectedValue) {
        setSelectedOrg(selectedValue);
        setOrganizationFilter(selectedValue);
    };
    var _l = useState(true), filterPaneIsVisible = _l[0], setFilterPaneIsVisible = _l[1];
    useEffect(function () {
        var fetchFilteredOrganizations = function () { return __awaiter(void 0, void 0, void 0, function () {
            var fetchedUsers, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        if (!name) return [3 /*break*/, 2];
                        return [4 /*yield*/, fetchOrganizations(ky, { name: name })];
                    case 1:
                        fetchedUsers = _a.sent();
                        setOrganizations(fetchedUsers);
                        return [3 /*break*/, 3];
                    case 2:
                        setOrganizations([]); // Clear user list if no filters are set
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        console.error('Error fetching organizations:', error_1);
                        setOrganizations([]); // Clear user list in case of error
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        fetchFilteredOrganizations();
    }, [name]);
    useEffect(function () {
        var queryParams = new URLSearchParams(location.search);
        var userId = queryParams.get('detail');
        setFilters({
            search: queryParams.get('search') || '',
            organization: queryParams.get('organization') || '',
        });
        setSearchFilter(queryParams.get('search') || '');
        setOrganizationFilter(queryParams.get('organization') || '');
        setSelectedOrg(queryParams.get('organization') || '');
        if (queryParams.get('organization')) {
            var fetchFilteredOrganization = function () { return __awaiter(void 0, void 0, void 0, function () {
                var fetchedOrganization, error_2;
                var _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _b.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, fetchOrganizationData((_a = queryParams.get('organization')) !== null && _a !== void 0 ? _a : '', ky)];
                        case 1:
                            fetchedOrganization = _b.sent();
                            setOrganizations([fetchedOrganization]);
                            return [3 /*break*/, 3];
                        case 2:
                            error_2 = _b.sent();
                            console.error('Error fetching organizations:', error_2);
                            setOrganizations([]); // Clear user list in case of error
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); };
            fetchFilteredOrganization();
        }
        ;
        if (userId) {
            fetchUsers(ky, { name: '', organization: '' }).then(function (users) {
                var user = users.find(function (p) { return p.id === userId; });
                if (user) {
                    setSelectedUser(user);
                    history.replace({ search: "?".concat(queryParams.toString()) });
                }
            });
        }
    }, [location.search]);
    var updateUrlWithFilters = function (filters) {
        var params = new URLSearchParams();
        if (filters.search)
            params.set('search', filters.search);
        if (filters.organization)
            params.set('organization', filters.organization);
        // if (filters.archived !== undefined) params.set('archived', filters.archived.toString());
        history.push({ search: params.toString() });
    };
    var handleClearFilters = function () {
        setSearchFilter('');
        setSelectedOrg('');
        // setArchivedFilter(false);
        setFilters({
            search: '',
            organization: '',
            // archived: false,
        });
        history.push({ search: '' });
    };
    var isAnyFilterActive = function () {
        return (!!filters.search || !!filters.organization
        // || filters.archived
        );
    };
    var _m = useQuery(['users', filters], function () { return fetchUsers(ky, filters); }, {
        enabled: isAnyFilterActive(),
        staleTime: 0,
        cacheTime: 0,
    }), _o = _m.data, usersData = _o === void 0 ? [] : _o, isLoading = _m.isLoading, isError = _m.isError;
    useEffect(function () {
        var fetchOrganizationsForUsers = function () { return __awaiter(void 0, void 0, void 0, function () {
            var organizationsMap, userIdsToFetch, _i, userIdsToFetch_1, userId, organizations_1, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        organizationsMap = {};
                        userIdsToFetch = usersData.filter(function (user) { return !userOrganizations[user.id]; }).map(function (user) { return user.id; });
                        _i = 0, userIdsToFetch_1 = userIdsToFetch;
                        _a.label = 1;
                    case 1:
                        if (!(_i < userIdsToFetch_1.length)) return [3 /*break*/, 6];
                        userId = userIdsToFetch_1[_i];
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, fetchUserOrganizations(ky, userId)];
                    case 3:
                        organizations_1 = _a.sent();
                        organizationsMap[userId] = organizations_1.map(function (org) { return org.organization.name; }).join(', ') || 'N/A';
                        return [3 /*break*/, 5];
                    case 4:
                        error_3 = _a.sent();
                        console.error('Error fetching organizations:', error_3);
                        organizationsMap[userId] = 'N/A'; // Fallback on error
                        return [3 /*break*/, 5];
                    case 5:
                        _i++;
                        return [3 /*break*/, 1];
                    case 6:
                        // Merge with existing organizations only if there are new entries
                        if (Object.keys(organizationsMap).length > 0) {
                            setUserOrganizations(function (prev) { return (__assign(__assign({}, prev), organizationsMap)); });
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        if (usersData.length > 0) {
            fetchOrganizationsForUsers();
        }
    }, [usersData, ky]); // Problematic dependency array
    var toggleFilterPane = function () { return setFilterPaneIsVisible(function (prev) { return !prev; }); };
    var columns = [
        {
            name: 'Příjmení',
            key: 'lastName', // This must be a valid key of User
            clickable: true,
            onClick: function (item) {
                var currentParams = new URLSearchParams(location.search);
                currentParams.delete('detail');
                currentParams.set('detail', item.id);
                history.push({
                    search: currentParams.toString(),
                });
                setSelectedUser(item);
            },
        },
        {
            name: 'Jméno',
            key: 'firstName', // This must be a valid key of User
        },
        {
            name: 'E-mail',
            key: 'email', // This must be a valid key of User
        },
        {
            name: 'Organizace',
            key: 'id', // This key should correspond to an actual property in User
            cellRenderer: function (item) {
                var orgName = userOrganizations[item.id]; // Get organization name from the state
                return orgName ? orgName : 'N/A'; // Return organization name or 'N/A'
            },
        },
    ];
    var source = new StripesConnectedSource({}, null);
    source.update = function () { };
    source.records = function () { return usersData; };
    source.resultCount = function () { return usersData.length; };
    source.totalCount = function () { return usersData.length; };
    source.pending = function () { return isLoading; };
    source.loaded = function () { return isAnyFilterActive(); };
    source.failure = function () { return isError ? { message: 'Error loading data' } : null; };
    source.failureMessage = function () { return 'Error loading data'; };
    source.fetchMore = function () { };
    source.fetchByBrowsePoint = function () { };
    source.fetchOffset = function () { };
    source.successfulMutations = function () { return []; };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: 'flex', height: '93.5vh' } },
            filterPaneIsVisible && (React.createElement(Pane, { defaultWidth: "25%", paneTitle: "Navigace & filtrov\u00E1ni", lastMenu: filterPaneIsVisible ? React.createElement(IconButton, { icon: "caret-left", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? 'Skrýt filters' : 'Show filters', style: { marginLeft: 'auto' } }) : React.createElement(React.Fragment, null) },
                React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } },
                    React.createElement(NavComponent, null),
                    React.createElement(Button, { buttonStyle: "none", id: "clickable-reset-all", disabled: !isAnyFilterActive(), marginBottom0: true, onClick: handleClearFilters },
                        React.createElement(Icon, { icon: "times-circle-solid" }, "Resetovat v\u0161echno")),
                    React.createElement(AccordionSet, null,
                        React.createElement(Accordion, { label: "Hled\u00E1n\u00ED", separator: false },
                            React.createElement(TextField, { placeholder: "Hledat...", value: searchFilter, onChange: function (e) { return setSearchFilter(e.target.value); } })),
                        React.createElement(Accordion, { label: "Organizace", separator: false },
                            React.createElement(Selection, { name: "orgSelection", value: selectedOrg, disabled: orgLoading, onChange: handleOrganizationSelection, asyncFilter: true, onFilter: handleOrganizationInput, dataOptions: organizations ? organizations.map(function (org) { return ({
                                    value: org.id,
                                    label: org.name,
                                }); }) : [] })),
                        React.createElement(Button, { onClick: function () {
                                setFilters(__assign(__assign({}, filters), { search: searchFilter, organization: organizationFilter }));
                                updateUrlWithFilters(__assign(__assign({}, filters), { search: searchFilter, organization: organizationFilter }));
                            }, marginBottom0: true }, "Aplikovat"))))),
            React.createElement(Pane, { defaultWidth: filterPaneIsVisible ? 'fill' : '100%', paneTitle: React.createElement(React.Fragment, null,
                    React.createElement("span", null, "Seznam u\u017Eivatel\u00FA")), paneSub: usersData.length > 0 ? "Nalezeno ".concat(usersData.length, " z\u00E1znam\u016F") : "Zadejte vyhledávací kritéria pro zahájení vyhledávání", actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () {
                                onToggle();
                                history.push('/brusers/users/create');
                            } }, "Nov\u00FD")));
                }, firstMenu: !filterPaneIsVisible ? React.createElement(IconButton, { icon: "caret-right", onClick: toggleFilterPane, "aria-label": filterPaneIsVisible ? 'Hide filters' : 'Show filters', style: { marginLeft: 'auto' } }) : React.createElement(React.Fragment, null) },
                React.createElement(TableComponent, { data: usersData, columns: columns, pageSize: 30, hasDataFetched: isAnyFilterActive, searchTerm: searchFilter, filterPaneIsVisible: filterPaneIsVisible, toggleFilterPane: toggleFilterPane, source: source })),
            selectedUser ? (React.createElement(Pane, { defaultWidth: "35%", paneTitle: "Detail u\u017Eivatele", onClose: function () {
                    setSelectedUser(null);
                    var queryParams = new URLSearchParams(location.search);
                    queryParams.delete('detail');
                    history.replace({ search: queryParams.toString() }); // Remove only the detail parameter
                }, dismissible: true, actionMenu: function (_a) {
                    var onToggle = _a.onToggle;
                    return (React.createElement(MenuSection, { label: "Akce", id: "actions-menu" },
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return history.push("/brusers/users/edit/".concat(selectedUser.id)); }, fullWidth: true }, "Zm\u011Bnit"),
                        React.createElement(Button, { buttonStyle: "dropdownItem", onClick: function () { return history.push("/brusers/users/reset/".concat(selectedUser.id)); }, fullWidth: true }, "Resetovat Heslo")));
                } },
                React.createElement(UserDetail, { user: selectedUser, setSelectedUser: setSelectedUser }))) : null)));
};
export default UsersList;
