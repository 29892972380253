var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { useOkapiKy } from '@folio/stripes/core';
import { Button, Pane, TextField, Row, Col, PaneFooter, AccordionSet, Accordion, Checkbox, Selection, ErrorModal } from '@folio/stripes/components';
import { fetchOrganizations } from '../../api/organizationService';
import { assignUserToOrganization, fetchUserById, fetchUserOrganizations, manageUserOrganization, removeUserFromOrganization, updateUser } from '../../api/usersService';
var queryClient = new QueryClient({
    defaultOptions: {
        mutations: {
            onSuccess: function () {
                queryClient.invalidateQueries('users');
            },
        },
    },
});
var UserEdit = function () {
    var _a;
    var id = useParams().id;
    var history = useHistory();
    var ky = useOkapiKy();
    var _b = useQuery(['user', id], function () { return fetchUserById(ky, id); }, { enabled: !!id }), userData = _b.data, userError = _b.error, isUserLoading = _b.isLoading;
    var _c = useQuery(['organizations', id], function () { return fetchUserOrganizations(ky, id); }, { enabled: !!id }), userOrganizationsData = _c.data, orgError = _c.error, isOrgLoading = _c.isLoading;
    useEffect(function () {
        if (userData && userOrganizationsData) {
            setFormValues(__assign(__assign({}, formValues), { firstName: userData.firstName, lastName: userData.lastName, email: userData.email, userOrganizations: userOrganizationsData }));
        }
    }, [userData]);
    var _d = useState(''), selectedOrganization = _d[0], setSelectedOrganization = _d[1];
    var _e = useState([]), addedOrganizations = _e[0], setAddedOrganizations = _e[1];
    var _f = useState([]), restoredOrganizations = _f[0], setRestoredOrganizations = _f[1];
    var _g = useState([]), removedOrganizations = _g[0], setRemovedOrganizations = _g[1];
    var _h = useState({}), errors = _h[0], setErrors = _h[1];
    var _j = useState(false), errorModalOpen = _j[0], setErrorModalOpen = _j[1];
    var _k = useState(''), errorMessage = _k[0], setErrorMessage = _k[1];
    var _l = useState({
        email: '',
        firstName: '',
        lastName: '',
        organization: [],
        userOrganizations: []
    }), formValues = _l[0], setFormValues = _l[1];
    var _m = useState(true), expandAll = _m[0], setExpandAll = _m[1];
    var _o = useState({
        userOrganizations: true,
        organization: true,
        profile: true,
        role: true,
    }), accordionStatus = _o[0], setAccordionStatus = _o[1];
    var mutation = useMutation(function (newUser) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, ky.post('users', { json: newUser })];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.json()];
            }
        });
    }); });
    var _p = useQuery(['organizations', {}], function () { return fetchOrganizations(ky, {}); }, { refetchOnWindowFocus: false }), organizations = _p.data, orgLoading = _p.isLoading;
    var handleChange = function (e) {
        var _a = e.target, name = _a.name, type = _a.type;
        setFormValues(function (prev) {
            var _a;
            return (__assign(__assign({}, prev), (_a = {}, _a[name] = e.target.value, _a)));
        });
    };
    var validateForm = function () {
        var newErrors = {
            email: '',
            firstName: '',
            lastName: '',
            password: '',
        };
        if (!formValues.email) {
            newErrors.email = 'Email je povinný.';
        }
        else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
            newErrors.email = 'Zadejte platný email.';
        }
        if (!formValues.firstName) {
            newErrors.firstName = 'Jméno je povinné.';
        }
        if (!formValues.lastName) {
            newErrors.lastName = 'Příjmení je povinné.';
        }
        setErrors(newErrors);
        return Object.values(newErrors).every(function (error) { return error === ''; });
    };
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var _i, addedOrganizations_1, org, _a, addedOrganizations_2, org, _loop_1, _b, removedOrganizations_1, orgUuid, error_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    e.preventDefault();
                    if (!validateForm()) {
                        return [2 /*return*/]; // Stop submission if there are errors
                    }
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 17, , 18]);
                    return [4 /*yield*/, updateUser(ky, id, {
                            email: formValues.email,
                            firstName: formValues.firstName,
                            lastName: formValues.lastName,
                            shouldChangePassword: userData === null || userData === void 0 ? void 0 : userData.shouldChangePassword,
                        })];
                case 2:
                    _c.sent();
                    _i = 0, addedOrganizations_1 = addedOrganizations;
                    _c.label = 3;
                case 3:
                    if (!(_i < addedOrganizations_1.length)) return [3 /*break*/, 6];
                    org = addedOrganizations_1[_i];
                    return [4 /*yield*/, assignUserToOrganization(ky, id, org.id, org.roles)];
                case 4:
                    _c.sent();
                    _c.label = 5;
                case 5:
                    _i++;
                    return [3 /*break*/, 3];
                case 6:
                    _a = 0, addedOrganizations_2 = addedOrganizations;
                    _c.label = 7;
                case 7:
                    if (!(_a < addedOrganizations_2.length)) return [3 /*break*/, 12];
                    org = addedOrganizations_2[_a];
                    if (!org.archived) return [3 /*break*/, 9];
                    return [4 /*yield*/, manageUserOrganization(ky, id, org.id, "archive")];
                case 8:
                    _c.sent();
                    return [3 /*break*/, 11];
                case 9:
                    if (!org.restore) return [3 /*break*/, 11];
                    return [4 /*yield*/, manageUserOrganization(ky, id, org.id, "restore")];
                case 10:
                    _c.sent();
                    _c.label = 11;
                case 11:
                    _a++;
                    return [3 /*break*/, 7];
                case 12:
                    _loop_1 = function (orgUuid) {
                        return __generator(this, function (_d) {
                            switch (_d.label) {
                                case 0:
                                    if (!(userOrganizationsData === null || userOrganizationsData === void 0 ? void 0 : userOrganizationsData.some(function (org) { return org.organization.id === orgUuid; }))) return [3 /*break*/, 2];
                                    return [4 /*yield*/, removeUserFromOrganization(ky, id, orgUuid)];
                                case 1:
                                    _d.sent();
                                    return [3 /*break*/, 2];
                                case 2: return [2 /*return*/];
                            }
                        });
                    };
                    _b = 0, removedOrganizations_1 = removedOrganizations;
                    _c.label = 13;
                case 13:
                    if (!(_b < removedOrganizations_1.length)) return [3 /*break*/, 16];
                    orgUuid = removedOrganizations_1[_b];
                    return [5 /*yield**/, _loop_1(orgUuid)];
                case 14:
                    _c.sent();
                    _c.label = 15;
                case 15:
                    _b++;
                    return [3 /*break*/, 13];
                case 16:
                    history.push("/brusers/users?search=Test&detail=".concat(id));
                    return [3 /*break*/, 18];
                case 17:
                    error_1 = _c.sent();
                    console.error("Error updating organizations:", error_1);
                    setErrorMessage("Došlo k chybě při aktualizaci organizací.");
                    setErrorModalOpen(true);
                    return [3 /*break*/, 18];
                case 18: return [2 /*return*/];
            }
        });
    }); };
    var handleCheckboxChange = function (index, field) {
        // Update form values
        setFormValues(function (prevFormValues) {
            var _a;
            var updatedOrganizations = __spreadArray([], prevFormValues.userOrganizations, true);
            updatedOrganizations[index] = __assign(__assign({}, updatedOrganizations[index]), (_a = {}, _a[field] = !updatedOrganizations[index][field], _a));
            return __assign(__assign({}, prevFormValues), { userOrganizations: updatedOrganizations });
        });
        // Update added organizations if applicable
        setAddedOrganizations(function (prevAddedOrganizations) {
            var _a;
            var updatedAddedOrganizations = __spreadArray([], prevAddedOrganizations, true);
            var addedOrgIndex = updatedAddedOrganizations.findIndex(function (org) { return org.id === formValues.userOrganizations[index].organization.id; });
            if (addedOrgIndex !== -1) {
                updatedAddedOrganizations[addedOrgIndex] = __assign(__assign({}, updatedAddedOrganizations[addedOrgIndex]), (_a = {}, _a[field] = !updatedAddedOrganizations[addedOrgIndex][field], _a));
            }
            return updatedAddedOrganizations;
        });
    };
    var handleRoleChange = function (orgIndex, role, isChecked) {
        var updatedUserOrg; // Explicitly type updatedUserOrg
        // Update form values
        setFormValues(function (prev) {
            var updatedOrganizations = __spreadArray([], prev.userOrganizations, true);
            updatedUserOrg = __assign({}, updatedOrganizations[orgIndex]); // Copy the object to avoid direct mutation
            // Make sure roles is initialized
            if (!updatedUserOrg.roles) {
                updatedUserOrg.roles = [];
            }
            // Update roles in userOrganizations without adding duplicates
            if (isChecked && !updatedUserOrg.roles.includes(role)) {
                updatedUserOrg.roles.push(role);
            }
            else if (!isChecked) {
                updatedUserOrg.roles = updatedUserOrg.roles.filter(function (r) { return r !== role; });
            }
            updatedOrganizations[orgIndex] = updatedUserOrg;
            return __assign(__assign({}, prev), { userOrganizations: updatedOrganizations });
        });
        // Update added organizations
        setAddedOrganizations(function (prevAddedOrganizations) {
            var _a;
            var updatedAddedOrganizations = __spreadArray([], prevAddedOrganizations, true);
            var addedOrgIndex = updatedAddedOrganizations.findIndex(function (org) { return org.id === updatedUserOrg.organization.id; });
            if (addedOrgIndex !== -1) {
                // Update existing organization in addedOrganizations without adding duplicates
                if (isChecked && !updatedAddedOrganizations[addedOrgIndex].roles.includes(role)) {
                    updatedAddedOrganizations[addedOrgIndex].roles.push(role);
                }
                else if (!isChecked) {
                    updatedAddedOrganizations[addedOrgIndex].roles = updatedAddedOrganizations[addedOrgIndex].roles.filter(function (r) { return r !== role; });
                }
            }
            else {
                // If the organization doesn't exist in addedOrganizations, add it
                updatedAddedOrganizations.push({
                    id: (_a = updatedUserOrg.organization.id) !== null && _a !== void 0 ? _a : '',
                    roles: isChecked ? [role] : [],
                    archived: false,
                    restore: false,
                });
            }
            return updatedAddedOrganizations;
        });
    };
    var expandAllSections = function () {
        setAccordionStatus({
            userOrganizations: true,
            organization: true,
            profile: true,
            role: true,
        });
        setExpandAll(true);
    };
    var collapseAllSections = function () {
        setAccordionStatus({
            userOrganizations: false,
            organization: false,
            profile: false,
            role: false,
        });
        setExpandAll(false);
    };
    var toggleExpandCollapseAll = function () {
        if (expandAll) {
            collapseAllSections();
        }
        else {
            expandAllSections();
        }
    };
    return (React.createElement(QueryClientProvider, { client: queryClient },
        React.createElement("div", { style: { display: 'flex', height: '93.5vh' } },
            React.createElement(Pane, { defaultWidth: "fill", paneTitle: "\u00DAprava u\u017Eivatele", footer: React.createElement(PaneFooter, { renderStart: React.createElement(Button, { type: "submit", buttonStyle: "primary", marginBottom0: true, onClick: handleSubmit }, "Ulo\u017Eit"), renderEnd: React.createElement(Button, { buttonStyle: "default", onClick: function () { return history.goBack(); }, marginBottom0: true }, "Zru\u0161it") }) },
                React.createElement(ErrorModal, { open: errorModalOpen, label: "Chyba", content: errorMessage, onClose: function () { return setErrorModalOpen(false); } },
                    React.createElement("div", null, errorMessage),
                    React.createElement(Button, { onClick: function () { return setErrorModalOpen(false); } }, "Zav\u0159\u00EDt")),
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12, style: { display: 'flex', justifyContent: 'flex-end' } },
                        React.createElement(Button, { onClick: toggleExpandCollapseAll, buttonStyle: "default" }, expandAll ? 'Sbalit všechny' : 'Rozbalit všechny'))),
                React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(AccordionSet, null,
                        React.createElement(Accordion, { label: "U\u017Eivatelsk\u00E9 informace", open: accordionStatus.profile, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { profile: !accordionStatus.profile })); } },
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextField, { label: "Jm\u00E9no", name: "firstName", value: formValues.firstName, onChange: handleChange, required: true, error: errors.firstName })),
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextField, { label: "P\u0159\u00EDjmen\u00ED", name: "lastName", value: formValues.lastName, onChange: handleChange, required: true, error: errors.lastName }))),
                            React.createElement(Row, null,
                                React.createElement(Col, { xs: 6 },
                                    React.createElement(TextField, { label: "Email", name: "email", value: formValues.email, onChange: handleChange, required: true, error: errors.email })))),
                        React.createElement(Accordion, { label: "Organizace u\u017Eivatele", open: accordionStatus.userOrganizations, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { userOrganizations: !accordionStatus.userOrganizations })); } }, (_a = formValues.userOrganizations) === null || _a === void 0 ? void 0 : _a.map(function (userOrganization, index) {
                            var _a, _b, _c;
                            return (React.createElement(Row, { key: index },
                                React.createElement(Col, { xs: 3 },
                                    React.createElement("strong", null, userOrganization.organization.name)),
                                React.createElement(Col, { xs: 3 },
                                    React.createElement(Checkbox, { label: "Spr\u00E1vce nakladatele", name: "publisherAdmin", checked: (_a = userOrganization.roles) === null || _a === void 0 ? void 0 : _a.includes('PublisherAdmin'), onChange: function (e) { return handleRoleChange(index, 'PublisherAdmin', e.target.checked); } }),
                                    React.createElement(Checkbox, { label: "Garant nakladatele", name: "publisherGuarantor", checked: (_b = userOrganization.roles) === null || _b === void 0 ? void 0 : _b.includes('PublisherGuarantor'), onChange: function (e) { return handleRoleChange(index, 'PublisherGuarantor', e.target.checked); } }),
                                    React.createElement(Checkbox, { label: "Pracovn\u00EDk agentury", name: "agencyWorker", checked: (_c = userOrganization.roles) === null || _c === void 0 ? void 0 : _c.includes('AgencyWorker'), onChange: function (e) { return handleRoleChange(index, 'AgencyWorker', e.target.checked); } })),
                                React.createElement(Col, { xs: 3 },
                                    React.createElement(Checkbox, { label: "Archivovat", name: "archived", checked: userOrganization.archived, onChange: function () { return handleCheckboxChange(index, 'archived'); } })),
                                React.createElement(Col, { xs: 3 },
                                    React.createElement(Checkbox, { label: "Obnovit", name: "restore", checked: userOrganization.restore, onChange: function () { return handleCheckboxChange(index, 'restore'); } })),
                                React.createElement(Button, { buttonStyle: "danger", onClick: function () {
                                        // Add to removedOrganizations
                                        setRemovedOrganizations(__spreadArray(__spreadArray([], removedOrganizations, true), [userOrganization.organization.id], false));
                                        // Remove from formValues.userOrganizations
                                        setFormValues(function (prevFormValues) { return (__assign(__assign({}, prevFormValues), { userOrganizations: prevFormValues.userOrganizations.filter(function (org) { return org.organization.id !== userOrganization.organization.id; }) })); });
                                        // Remove from addedOrganizations if it exists
                                        setAddedOrganizations(function (prevAddedOrganizations) {
                                            return prevAddedOrganizations.filter(function (addedOrg) { return addedOrg.id !== userOrganization.organization.id; });
                                        });
                                    } }, "Odstranit")));
                        })),
                        React.createElement(Accordion, { label: "Organizace", open: accordionStatus.organization, onToggle: function () { return setAccordionStatus(__assign(__assign({}, accordionStatus), { organization: !accordionStatus.organization })); } },
                            React.createElement(Col, { xs: 6 },
                                React.createElement(Selection, { label: "Organizace", name: "organization", error: errors.organization, value: selectedOrganization, disabled: orgLoading, onChange: function (value) {
                                        // Update formValues with the new organization and userOrganizations
                                        setFormValues(function (prev) { return (__assign(__assign({}, prev), { organization: __spreadArray(__spreadArray([], prev.organization, true), [value], false) })); });
                                        setSelectedOrganization(value);
                                    }, dataOptions: organizations ? organizations.map(function (org) { return ({
                                        value: org.id,
                                        label: org.name,
                                    }); }) : [] })),
                            React.createElement(Button, { onClick: function () {
                                    var selectedOrg = organizations === null || organizations === void 0 ? void 0 : organizations.find(function (org) { return org.id === selectedOrganization; });
                                    if (selectedOrg) {
                                        // Check if the organization already exists in userOrganizations
                                        var orgExists = formValues.userOrganizations.some(function (org) { return org.organization.id === selectedOrg.id; });
                                        if (orgExists) {
                                            // Set error if duplicate organization is found
                                            setErrors(function (prev) { return (__assign(__assign({}, prev), { organization: "Organizace \"".concat(selectedOrg.name, "\" je ji\u017E p\u0159id\u00E1na.") })); });
                                        }
                                        else {
                                            setErrors(function (prev) { return (__assign(__assign({}, prev), { organization: undefined })); });
                                            // Create a new UserOrganization object with basic details
                                            var newUserOrg_1 = {
                                                user: userData, // assuming userData is already fetched and non-null
                                                organization: selectedOrg,
                                                roles: [], // Add default roles or leave empty
                                                archived: false,
                                            };
                                            var addUserOrg = {
                                                id: selectedOrg.id,
                                                archived: false,
                                                restore: false,
                                                roles: [],
                                            };
                                            setFormValues(function (prev) { return (__assign(__assign({}, prev), { userOrganizations: __spreadArray(__spreadArray([], prev.userOrganizations, true), [newUserOrg_1], false) })); });
                                            setAddedOrganizations(__spreadArray(__spreadArray([], addedOrganizations, true), [addUserOrg], false));
                                        }
                                    }
                                } }, "P\u0159idat organizaci"))))))));
};
export default UserEdit;
