import React from 'react';
import { ButtonGroup, Button } from '@folio/stripes/components';
import { useHistory, useLocation } from 'react-router-dom';
var NavigationComponent = function () {
    var history = useHistory();
    var location = useLocation();
    // Define your routes
    var routes = [
        { path: '/brusers/my-profile', label: 'Můj profil' },
        { path: '/brusers/organizations', label: 'Organizace' },
        { path: '/brusers/users', label: 'Uživatelé' },
    ];
    // Determine the active route
    var currentPath = location.pathname;
    return (React.createElement(ButtonGroup, { style: { marginBottom: '1rem' }, fullWidth: true }, routes.map(function (route) {
        var isActive = currentPath.startsWith(route.path);
        return (React.createElement(Button, { key: route.path, onClick: function () { return history.push(route.path); }, style: {
                backgroundColor: isActive ? '#1960a4' : '',
                color: isActive ? '#fff' : '',
                fontWeight: isActive ? 'bold' : '',
            } }, route.label));
    })));
};
export default NavigationComponent;
