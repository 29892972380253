import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import UsersList from './UsersList';
import UserCreate from './UserCreate';
import UserEdit from './UserEdit';
import UserReset from './UserReset';
function Users() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var cleanPath = path.replace('/*', '');
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: UsersList }),
        React.createElement(Route, { path: "".concat(cleanPath, "/create"), component: UserCreate }),
        React.createElement(Route, { path: "".concat(cleanPath, "/edit/:id"), component: UserEdit }),
        React.createElement(Route, { path: "".concat(cleanPath, "/reset/:id"), component: UserReset })));
}
export default Users;
