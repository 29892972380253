import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import MyProfileDetail from './MyProfileDetail';
import MyProfilePassword from './MyProfilePassword';
import SelectOrganization from './SelectOrganization';
function MyProfile() {
    var path = useRouteMatch().path; // useRouteMatch to get the base path
    var cleanPath = path.replace('/*', '');
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: cleanPath, component: MyProfileDetail }),
        React.createElement(Route, { path: "".concat(cleanPath, "/password"), component: MyProfilePassword }),
        React.createElement(Route, { path: "".concat(cleanPath, "/select-organization"), component: SelectOrganization })));
}
export default MyProfile;
